import React from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"

import Layout from "../components/layouts"
import SEO from "../components/seo"
import Button from "../components/ui/Button"
import Typography from "../components/ui/Typography"

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 10rem;
  height: calc(100vh - ${(props) => props.theme.navbar.height});
  h1 {
    ${Typography.Large};
    margin-bottom: 0;
    font-weight: normal;
  }
`

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Not found" />
      <Container>
        <div>
          <h1>{t("we_apologize")}</h1>
          <p>{t("page_not_available")}.</p>
          <Button onClick={() => navigate("/")}>{t("return_home")}</Button>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
